<template>
  <div class="createSuccess">
    <div class="success-icon">
      <Icon
        name="passed"
        size="100"
        color="#07C160"
      ></Icon>
      <h1 class="success-text">Created successfully</h1>
    </div>
    <Button
      class="success-btn"
      @click="goAccoundDetail"
    >View Details</Button>
  </div>
</template>

<script>
import { Icon, Button } from 'vant'
import './index.less'
export default {
  components: {
    Icon,
    Button
  },
  data () {
    return {
    }
  },
  methods: {
    goAccoundDetail () {
      this.$emit('goAccounDetail', this.$route.query.id)
    },
    onNavBarClickLeft () {
      // 增加返回
      this.$router.push({
        path: '/newCreate'
      })
    }
  }
}
</script>

<style>
</style>